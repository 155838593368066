.Modal {
    font-family: 'Lato', sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
    // overflow: hidden;
    outline: 0;
    // margin-left: 35% !important; //Temporary solution
    padding-right: 16px;
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: var(--primaryFont);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;   
}


.modaldialog {
    position: relative;
    width: auto;
    // margin: 0.5rem;
    pointer-events: none;
}
.largedialog{
    position: relative;
    width: auto;
    // margin: 0.5rem;
    pointer-events: none;
}

.modaldialogcentered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modalcontent {
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 0.42rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.342);
    outline: 0;
}

.modalheader {
    text-align: center;
}

.modalbody {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.75rem;
}

.modalfooter {
    text-align: center;
    padding: 1.5rem;
}


@media (min-width: 576px){
    .modaldialogcentered {
        min-height: calc(100% - 3.5rem);
    }
    .modaldialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .largedialog {
        max-width:fit-content;
        margin: 1.75rem auto;
    }
}

