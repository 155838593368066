.custom-file-input-upload {
  color: transparent;
  padding: 1em 0;
}
.custom-file-input-upload::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input-upload::before {
  content: "";
  color: #333;
  display: inline-block;
  width: 24rem;
  border: 1px solid #333;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 1.45em .5em;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 500;
  font-size: 12px;
}
.custom-file-input-upload:hover::before {
  border-color: #333;
}
.custom-file-input-upload:active {
  outline: 0;
}
.custom-file-input-upload:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.browse-btn {
  margin: 1rem 1.5rem 0 0;
  padding: 0.68em 0.7em;
  border: 1px solid #333;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
}

.browse-btn:hover {
  background-color: #e3e3e3;
}