.card {
  margin: 4px;
  padding: 4px;
  .minus,
  .plus {
    border-radius: 50%;
    border-color: var(--primaryColor);
    line-height: initial;
    text-decoration: none;
    font-family: var(--primaryFont);
  }
  .minus {
    border: 1px solid var(--thirdColor);
  }
  .plus {
    border: 1px solid green;
  }
  .counter {
    margin-inline: 8px;
  }
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-block: 8px;
  background-color: rgba(249, 132, 53, 0.15);
  font-weight: 700;
}

.request-box {
  justify-content: center;
  display: flex;
}
.counter {
  width: 40px;
  border: none;
  margin-inline: 8px;
  outline: none;
  text-align: center;
}
.debit {
  display: flex;
  font-weight: bolder;
  margin-block: 16px;
  .inputGroup {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
  input[type="radio"] {
    accent-color: var(--thirdColor);
  }
}
