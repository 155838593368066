// Admin users styles
.admin-user-cards {
  background: var(--primaryColor);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 14vh;
  padding: 1rem  ;
}
.text-theme {
  color: var(--thirdColor);
}

.admin-user-dropdown {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;

  span {
    padding-right: 5rem;
    color: #9e9e9e;
  }
}
.manage-users-btn {
  font-family: "Lato", sans-serif;
  font-size: 10px;
  background: #f2f2f2;
  border: 0.5px solid #e5e5e5;
  box-shadow: 0px 0px 0px #e5e5e5;
  border-radius: 2px;
  padding: 13px 10px;
}

.change-role-btn {
  background: #ffffff;
  border: 1px solid #d0cfcf;
  border-radius: 3px;
  color: #6a6a6a;
}

.admin-user-inputs {
  border: 1px solid #000000;
  width: 100%;
  border-radius: 5px;
  padding: 6px 5px;
}

.admin-users-table {
  background: #ffffff;
  border-radius: 8px;
}

.t-head-colored {
  background-color: #f9843533;
  border-radius: 5px;
  color: #e7472c;
  font-size: 13px;
  // padding: 20px;
}

// manage admin users
.manage-admin-users-container {
  min-height: 30%;
  background: #ffffff;
  border: 0.5px solid #e0e0e0;
  box-shadow: 0px 0px 0px #e5e5e5;
  border-radius: 4px;
  padding: 0 ;
  font-family: 'Inter', sans-serif;
}

.role-user-list {
  border: 1px solid #f1f1f1;
  border-radius: 2px;
  color: #828282;
  padding: 0.4rem;
  p {
    font-size: 12px;
    span {
      font-weight: 600;
    }
  }
}

.role-list-wrapper {
  color: #828282;
  list-style-type: none;
  padding: 0;
  margin: 0;

  .role-list-item {
    padding: 1rem 1.2rem;
    border-bottom: 0.5px solid #f2f2f2;
  }

  .active-role {
    background-color: #ffefe9;
    color: #FF6700;
  }
}

.br-right {
  border-right: 0.5px solid #e0e0e0;
}

.role-permissions-table {
  border: 1px solid #219653;
  border-radius: 6px;
}
.role-permissions-thead {
  background-color: #e8f1ed;
  th {
    font-weight: 500;
    color: #52936f;
  }
}
.role-restrictions-thead {
  background-color: #f9f6f6;
  th {
    font-weight: 500;
    color: #c67777;
  }
}

.pointer{
  cursor: pointer;
}

.goback{
  span{
    position: absolute;
    top: -40%;
  }
}

// corporate kyc

