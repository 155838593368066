#wrapper {
  // background-color: #eeeff8;
}

#sidebar {
  height: 100vh;
  margin-top: -65px !important;
  position: fixed !important;
}

#notification-sidebar {
  height: 100%;
  top: 65px;
  position: fixed !important;
  right: 0;
}

#main {
  margin: 25px 20px 40px 330px;
  padding-right: 10px;
}

#header {
  margin-left: 300px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

@media (max-width: 1000px) {
  #header {
    margin: auto;
  }

  #main {
    position: relative;
    margin: 40px 10px 40px 0px;
    padding-right: 10px;
  }
}
