.res-button{
    font-size: 12px;
    font-weight: 300;
    width: 103px;
    height: 32px;
    text-align: center;
    color: var(--primaryColor);
    box-sizing: border-box;
    border-radius: 3px;
    font-family: var(--primaryFont);
    max-width: 103px;
}

.waya-cards{
    padding: 26px 13px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    font-family: var(--primaryFont);
}

.auth-card{
    padding: 20px 19px;
    background: #FBFBFB;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-family: var(--primaryFont);
}

.Nav-Bar{
    background-color: #F2F2F2;
    margin: -8px -29px 0px -54px;
    position: static;
    font-family: var(--primaryFont);
    // font-weight: 600;
}
  
.nav-navigation{
    margin-left:70px;
}
  
.nav-ul{
    list-style: none;
    padding: 0px;
    margin-bottom: 25px;
    li{
        text-decoration: none;
        font-size: 14px;
        line-height: 18px;
        color: #858585;
        letter-spacing: 0.4px;
        padding: 10px 0px 10px 0px;
        margin-right: 38px;
    }
  
    li:hover{
      color: var(--fontColor);
    //   font-weight: 600;
      cursor: pointer;
    }
  }
  
  .nav-ul li{
    display: inline-block;   
}
  
  
.nav-li-active{
    // border-bottom: 4px solid var(--primaryColor);
    // padding: 0.5rem 1rem;
    background-color: var(--thirdColor);
    color: var(--primaryColor) !important;
    border-radius: 30px;
    padding: 10px 15px !important;
}

.dispute-btns:hover {
    opacity: 0.9;
}