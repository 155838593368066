.input-styles {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    margin-top: 1em;
}

.checkbox-container2 {
    margin: 1em -0.5em -1em;
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container2 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark2 {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 16.5em;
    height: 22px;
    width: 22px;
    background-color: #eee;
    border-radius: 5px;
  }
  
  .checkbox-container2:hover input ~ .checkmark2 {
    background-color: #ccc;
  }
  
  .checkbox-container2 input:checked ~ .checkmark2 {
    background-color: #FF6700;
  }
  
  .checkmark2:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .checkbox-container2 input:checked ~ .checkmark2:after {
    display: block;
  }
  
  span ~ input[type="checkbox"]:checked {
    color: #FF6700;
   }
  
  .checkbox-container2 .checkmark2:after {
    left: 7px;
    top: 3px;
    width: 8px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }