@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap");

body {
  background-color: #fafafa !important;
  font-family: var(--primaryFont);
  // background:  #f3f6f9;
}
:root {
  --primaryFont: "DM Sans", sans-serif;
  --secondaryFont: "Poppins", sans-serif;
  --menuColor: #abb4be;
  --primaryColor: #ffffff;
  --secondaryColor: #171717;
  --thirdColor: #FF6700;
  --thirdColorLight: rgba(231, 71, 44, 0.16);
  --fontColor: #3a3837;
  --sideBarBg: #1d2127;
  --defaultColor: #828282;
}

.fs-05 {
  font-size: 5px !important;
}

.fs-06 {
  font-size: 6px !important;
}

.fs-8 {
  font-size: 8px !important;
}
.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.full-width {
  width: 100%;
}

.m-0 {
  margin: 0 !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-22 {
  margin-left: 22px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.mt-02 {
  margin-top: 2px !important;
}

.mt-05 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mr-02 {
  margin-right: 2px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-30 {
  margin-right: 30px;
}

.p-30 {
  padding: 30px;
}

.p-20 {
  padding: 20px;
}

.text-white {
  color: var(--primaryColor);
}

.text-semi-white {
  color: #ffcfbb;
}

.text-default {
  color: var(--defaultColor);
}

.text-orange {
  color: var(--thirdColor);
}

.text-sharp-green {
  color: #05b862 !important;
}

.text-green {
  color: #0becc7 !important;
}

.text-yellow {
  color: #d4af37 !important;
}

.text-grey {
  color: #4f4f4f !important;
}

.text-deep-grey {
  color: #454545cc !important;
}

.text-darker {
  color: #000000 !important;
}

.text-semi-dark {
  color: #00000099 !important;
}

.text-red {
  color: #ff0000 !important;
}
.text-muted {
  color: #e8e3e3d1;
}

.font-default {
  font-family: var(--primaryFont);
}

.font-secondary {
  font-family: var(--secondaryFont);
}

.cursor-pointer {
  cursor: pointer;
}

.left-43 {
  left: 43% !important;
}

.width-35 {
  width: 35%;
}

.width-50 {
  width: 50%;
}

.width-70 {
  width: 70%;
}

.br-10 {
  border-radius: 10px !important;
}
.br-20 {
  border-radius: 20px !important;
}

.br-30 {
  border-radius: 30px !important;
}
.border-red {
  border: 1px solid #FF6700;
}
.data-table {
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-family: var(--primaryFont);
}

.extra-padding {
  padding: 13px 100px !important;
}

.bg-inherit {
  background-color: inherit;
}

.bg-white {
  background-color: var(--primaryColor);
}

.bg-grey {
  background-color: #e0e0e0;
}

.bg-red {
  background-color: #f63002;
}

.bg-green {
  background-color: #05b862;
}

.bg-dark {
  background-color: #454545;
}

.bg-orange {
  background-color: var(--thirdColor);
}

.bg-orange-light {
  background-color: var(--thirdColorLight);
}

.active-option {
  background-color: var(--thirdColor);
  padding: 13px 25px 4px;
  border-radius: 30px;
  font-weight: 400;
  color: white;
}

.inactive-option {
  padding: 13px 14px 4px;
  font-weight: 400;
  // border-radius: 30px;
}
.input-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 5px 0px;
  font-family: var(--primaryFont);
}

.input-container {
  /* width: calc(100% - 40px);
   */
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 5px 15px;
  border-radius: 4px;
  margin: 10px 0px;
  position: relative;
}

.inputnoBorder {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  /* border-bottom: none !important; */
  border-radius: 0px !important;
  padding-left: 0px !important;
}
.muted-input {
  // width: calc(100% - 40px);
  border: 1px solid #b5b5b5;
  padding: 7px 10px;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
  font-family: var(--primaryFont);
}
.input_Search {
  width: calc(100% - 40px);
  border: 1px solid #FF6700;
  padding: 7px 10px;
  border-radius: 4px;
  position: relative;
  background-color: #fff;
  font-family: var(--primaryFont);
}

.inputsHolder {
  width: 100%;
  margin-top: 20px;
}

.input {
  background: none;
  border: none;
  width: 100%;
  outline: 0;
  font-size: 14px;
  color: var(--fontColor);
  font-family: var(--primaryFont);
}

.input::placeholder {
  font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input:disabled,
.input[readonly] {
  background-color: #f3f6f9;
  opacity: 1;
  // border-color: #F3F6F9;
}
.custom-file-input-wrapper {
  text-align: center;
  // padding: 3% 4%;
  background: #e0e0e0;
  border: 1px dashed rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.custom-file-input {
  display: none;
}

.container-disabled {
  background-color: #f3f6f9;
  opacity: 1;
  // border-color: #F3F6F9;
}

.th {
  font-weight: 700;
  font-family: var(--primaryFont);
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #ebedf3 !important;
  color: #f65904;
  font-size: 13px;
  text-transform: uppercase;
  // letter-spacing: 0.2em;
}

.td {
  color: #000000cc;
  font-family: var(--primaryFont);
  font-size: 13px;
  vertical-align: middle;
  padding: 0.7rem 0.7rem !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important ;
  font-weight: 500;
  line-height: 30px;
}
.tier-td {
  color: #000000cc;
  font-family: var(--primaryFont);
  font-size: 12px;
  vertical-align: bottom;
  padding: 2em 0.7rem 0.6em 0.7em !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important ;
  font-weight: 500;
  line-height: 30px;
}

tr:hover {
  cursor: pointer;
}

.tabactive {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px 5px;
  text-align: center;
  background: rgba(5, 184, 98, 0.15);
  color: #05b862;
  border: 1px solid #05b862;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: var(--primaryFont);
}

.tabtransparent {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  text-align: center;
  background: transparent;
  color: #6a6a6a;
  border: 1px solid #d0cfcf;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: var(--primaryFont);
}

.tabkyc {
  font-size: 12px;
  font-weight: 500;
  padding: 7px 8px;
  text-align: center;
  color: #000000;
  box-sizing: border-box;
  font-family: var(--primaryFont);
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 0px #e5e5e5;
  border-radius: 4px;
}

.tabissued {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: var(--primaryFont);
}

.tabdamaged {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  border-radius: 2px;
  font-family: var(--primaryFont);
}

.tabblack {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  text-align: center;
  color: #000000;
  background: transparent;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: var(--primaryFont);
}

.tabpending {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  text-align: center;
  color: #d4af37;
  background: transparent;
  border: 1px solid#D4AF37;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: var(--primaryFont);
}

.tabdanger {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 10px;
  text-align: center;
  color: #ff4b01;
  background: rgba(255, 75, 1, 0.1);
  border: 1px solid #ff4b01;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: var(--primaryFont);
}

.actionDanger {
  font-size: 12px;
  font-weight: 400;
  padding: 2px 7px 4px;
  text-align: center;
  color: #ff4b01;
  background: rgba(255, 102, 52, 0.2);
  border: 1px solid #FF6700;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: var(--primaryFont);
}

.actionSuccess {
  font-size: 12px;
  font-weight: 400;
  padding: 2px 14px 4px;
  text-align: center;
  color: #05b862;
  background: rgba(5, 184, 98, 0.2);
  border: 1px solid #05b862;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: var(--primaryFont);
}

.actionBlue {
  font-size: 12px;
  font-weight: 400;
  padding: 2px 7px 4px;
  text-align: center;
  color: #064a72;
  background: rgba(6, 74, 114, 0.2);
  border: 1px solid #064a72;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: var(--primaryFont);
}

.action-lightBlue {
  font-size: 12px;
  font-weight: 400;
  padding: 2px 7px 4px;
  text-align: center;
  color: #0066ff;
  background: rgba(0, 102, 255, 0.1);
  border: 1px solid #0066ff;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: var(--primaryFont);
}

.action-purple {
  font-size: 12px;
  font-weight: 400;
  padding: 2px 7px 4px;
  text-align: center;
  color: #644cfa;
  background: rgba(100, 76, 250, 0.34);
  border: 1px solid #644cfa;
  box-sizing: border-box;
  border-radius: 3px;
  font-family: var(--primaryFont);
}

.filter-contents {
  width: 100%;
  padding: 10px 20px;
  margin: 10px 0px;
  border: 1px solid #e2e0e0;
  box-sizing: border-box;
  background-color: var(--primaryColor);
  font-family: var(--primaryFont);
}

.filter-search {
  width: 70%;
}

.tableHeaders {
  width: 100%;
}

.white-icon {
  fill: var(--primaryColor);
}

.grey-icon {
  fill: #858585;
}

.textarea {
  height: 120px;
}

textarea {
  overflow: auto;
  resize: vertical;
}

.single {
  width: 35px;
  border: none;
  background: none;
  border-bottom: 2px solid #000000;
  height: 30px;
  outline: 0;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--primaryFont);
}

.select {
  width: 100%;
  display: grid;
  cursor: pointer;
  line-height: 1.1;
  background-color: #fff;
  grid-template-areas: "select";
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 101%;
  background-position-y: 0px;
  padding-bottom: 5px;
  padding-top: 4px;
  padding-left: 5px;
  height: 10%;
  font-family: var(--primaryFont);
}

select,
.select:after {
  grid-area: select;
}

.select::-ms-expand {
  display: none;
}

.select > option {
  border: 1px solid red;
}

button[disabled]:hover {
  cursor: not-allowed;
}

button[disabled] {
  opacity: 0.4;
}

.orange-button {
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--thirdColor);
  color: var(--primaryColor);
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  border-color: transparent;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: var(--primaryFont);
}

.button-success {
  padding: 0.8em 1.7em;
  cursor: pointer;
  border-radius: 2px;
  background-color: #05b862;
  color: var(--primaryColor);
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  border-color: transparent;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: var(--primaryFont);
}

.button-white {
  border: none;
  border-color: transparent;
  border-radius: 5px;
  color: #000;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px 25px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: var(--primaryFont);
}

.export-button {
  padding: 0.3rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
  // border-radius: 4px;
  // background-color: rgb(39, 174, 96);
  color: var(--thirdColor);
  font-size: 13px;
  text-align: middle;
  font-family: var(--primaryFont);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.request-button {
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
  border-radius: 4px;
  background-color: rgb(255, 68, 0);
  color: var(--primaryColor);
  font-size: 13px;
  text-align: left;
  font-family: var(--primaryFont);
  border: 0;
}

.primary-button {
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
  border-radius: 4px;
  background-color: #4a4aff;
  color: var(--primaryColor);
  font-size: 13px;
  text-align: left;
  font-family: var(--primaryFont);
}

.change-button {
  padding: 13px 35px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
  color: var(--thirdColor);
  font-size: 16px;
  text-align: left;
  font-family: var(--primaryFont);
  background: rgba(217, 213, 236, 0.29);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: none;
}

.login-text {
  color: var(--fontColor);
  line-height: 29px;
}

.login-header {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
}

.tier {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 0px #e5e5e5;
  border-radius: 4px;
}
.tier-detail {
  background: #f8f8f8;
  border: 1px dashed #e0e0e0;
  box-shadow: 0px 0px 0px #e5e5e5;
  border-radius: 2px;
  padding: 0 0 0 0.4rem;
  color: #000000;
  font-weight: 500;
  font-size: 12px;
  // min-width: 150%;
  cursor: pointer;
}

.kyc-table {
  background: #ffffff;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.1);
}

.kyc-action {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0px 0px #e5e5e5;
  border-radius: 2px;
  // padding: 7px 15px;
  font-family: var(--primaryFont);
}

.no-click {
  pointer-events: none;
}
.opaque {
  opacity: 0.5;
}

.status-btn {
  padding: 0.7em 1.8em;
  cursor: pointer;
  border-radius: 12px;
  color: var(--primaryColor);
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  border-color: transparent;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: var(--primaryFont);
}
.successful {
  background-color: #05b862;
}
.failed {
  background-color: #f63002;
}
.pending {
  background: #f3ce00;
}

.settlement-details-wrapper {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  // background-color: #ffffff;
}

.clear-filter {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  align-items: center;
  margin: 0px 5px;
  border-radius: 4px;
  font-size: 13px;
  text-align: left;
  font-family: var(--primaryFont);
}
.apply-filter {
  background: #FF6700;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  align-items: center;
  margin: 0px 5px;
  border-radius: 4px;
  color: var(--primaryColor);
  font-size: 13px;
  text-align: left;
  font-family: var(--primaryFont);
}

.popOver {
  position: absolute;
  transition: all 0.5s ease;
  margin-top: -3em;
  margin-left: -7em;
  border-radius: 4px;
  width: 14em;
  font-weight: normal;
  color: #454545;
  background-color: #fff;
  list-style-type: none;
  font-size: 0.85rem;
  font-weight: bold;
  box-shadow: 0px 0px 1px 1px #ccc;
  z-index: 9;
  padding: 0;
}

.popover-list-item {
  width: 100%;
  padding-left: 1em;

  a {
    text-decoration: none;
    color: #454545;
  }
}

.popover-list-item:hover {
  background-color: #ededed;
}

.copy-icon {
  color: #bbb;
}

.copy-icon:hover {
  color: #3a3837;
}

.table-responsive {
  overflow-x: scroll;
}
.collapse-btn {
  min-width: 200px;
  text-align: initial;
  &.dropdown-toggle::after {
    margin-top: 10px;
  }
}
.remove-btn-styles {
  color: inherit;
  background: inherit;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: inherit;
  font-family: inherit;

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    background: inherit;
    border: none;
    outline: none;
    box-shadow: none;
  }
}
.custom-button {
  .btn-outline-primary {
    @extend .remove-btn-styles;
  }
}
.no-btn.btn-primary {
  @extend .remove-btn-styles;
}
// .data-table--scroll {
//   display: block;
//   width: 100%;
//   overflow: scroll;
// }

// .data-table--scroll::-webkit-scrollbar {
//   width: 20px;
// }

// /* Track */
// .data-table--scroll::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }

// /* Handle */
// .data-table--scroll::-webkit-scrollbar-thumb {
// background: #FF6700;
//   border-radius: 10px;
// }

// /* Handle on hover */
// .data-table--scroll::-webkit-scrollbar-thumb:hover {
//   background: #FF6700;
// }

.opacity-4 {
  opacity: 0.4;
}

.text-smaller {
  font-size: 0.75rem;
}

.commission-input {
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 13px;
}

.commssion-input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 5px 0px;
  font-family: var(--primaryFont);
}
