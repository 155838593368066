.tab__container{
    background-color: #E0E0E0;
    border-radius: 24px
}

.tab{
    background-color: transparent;
    border: none;
    padding: 8px 20px;
    font-size: 15px;
    color: #333333;
    border-radius: 24px;
}
.tab:hover{
    background-color: #eeeeee;
    border-radius: 0
}
.active__tab{
    background-color: #FF6700;
    border: none;
    padding: 8px 20px;
    font-size: 15px;
    color: #ffffff;
    border-radius: 24px;
}