.input-container {
  display: flex;
  gap: 4px;
}

.issued {
  display: flex;
  align-items: center;
  justify-content: center;

  label {
    margin-right: 8px;
  }
  input {
    background-color: #FF6700;
  }
}
